import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"
import debraimage from "../images/profile/deb.png"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <Layout>
      <Seo title={post.frontmatter.title} />
      <HeroMini title={post.frontmatter.title} />
      <main className="section has-text-black">
      <div className="container">     
      <div className="mb-4" style={{display:"flex"}}>
      <figure style={{marginBottom:0}}><img style={{borderRadius:"50%", maxWidth:"80px"}}  className="is-rounded" alt={post.frontmatter.author} src={debraimage}/></figure>
  	  <div><span style={{position:"absolute", top:10, left:70}}>By {post.frontmatter.author}</span></div>
    </div>

          <div className="columns content">
              <div className="column has-text-black">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </div>
          </div>
      </div>
      </main>
    </Layout>    
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
      }
    }
  }
`